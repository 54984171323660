body, #root{    
  margin: 0;
  padding: 0;
}
html{
  scroll-padding-top:150px;
}

#wrapper{
  width:100%;        
  margin: 0;
  padding: 0;
  display:flex;
  align-items:flex-start;
  flex-wrap: nowrap;
}

#listes{
  border-radius: 10px;
  padding:10px;
  box-shadow: 0 0 0.3rem #999;
  position:-webkit-sticky;
  position:sticky;
  top:0px;
  width: 400px;
  overflow-y: auto;
  max-height: 100vh;
}

#slbuttons{
  position:absolute;
  right:0px;
  top:0px;
}

#slbuttons .mainButton{
  margin: 5px;
}
listeRecettes{
  display:flex;
  flex-direction:column;
}
#addIng{
  display:flex;
}
li.done{
  text-decoration: line-through;
}
.recipeList{
  width:100%;
  display:flex;
  justify-content:space-between;
}

.btnList{
  font-size: 12px;
  font-weight: bold;
  border-radius: 15px;
  padding:6px;
}


#wrap{
  width: 960px;
  max-width: 100%;
  margin: 0 auto;
}

@media(max-width:480px) {
  #wrap{
    display: none;
  }
}

header{
  padding:5px;
	position: -webkit-sticky;
	position: sticky;
 	top: 0; 
  margin:0 auto;
  z-index: 2;
  max-width:100%;
  background-color: #9de8fd;
  box-shadow: 0 0 0.3rem #999;
  text-align:center;
}

.mainButton{
  font-size: 15px;
  font-weight: bold;
  border-radius: 10px;
  padding:10px;
  margin:0px 20px; 
  white-space: nowrap;
  background-color:#DDD;
}

.mainButton:hover{
  background-color:#CCC;
}

.active,.mainButton.active:hover{
  background-color:#ADB;
  border:#9BB solid 2px;
}

main{
  margin: 0 auto;
  padding: 10px;
  box-shadow: 0 0 0.3rem #999;
}

article{
  padding: 10px 20px;
  box-shadow: 0 0 0.3rem #999;
  border-radius: 10px;
  margin:15px;
  display:flex;
  align-items:flex-start;
}

article summary{
  font-size: 28px;
  font-weight: bold;
}

article :nth-last-child(1){
  margin-left: auto;
}


.buttonAdd {  
  height: 1.2em;
  line-height: 1em;  
  width: 1.2em;  
  font-size: 2em;
  font-weight: bold;
  border-radius: 50%;
  background-color: #4CAF50;
  color: white;
  text-align: center;
  cursor: pointer;
}

.buttonRemove {
  margin: 0 10px;
  flex-shrink:0;
  height: 1.2em;
  line-height: 0.2em;   
  padding: 0 0 0.2em 0;
  width: 1.2em;  
  font-size: 2em;
  font-weight: bold;
  border-radius: 50%;
  background-color: #E43;
  color: white;
  text-align: center;
  cursor: pointer;
}




footer{
  text-align: end;
}

